







































import { Component, Vue, Prop, Ref, Emit } from 'vue-property-decorator';
import * as consts from '../../consts';
import moment from 'moment';
import * as helpers from '../../helpers';
import * as types from '../../types';

@Component
export default class ModalMethod extends Vue {
  @Prop({ type: String, required: false, default: '' }) title;
  @Prop({ type: String, required: false, default: '' }) message;
  @Prop({ type: String, required: false, default: '' }) messageClass;
  @Prop({ type: String, required: false, default: '' }) titleClass;
  @Prop({ type: String, required: false, default: 'lg' }) size;

  leftArrowSign = '<<';
  rightArrowSign = '>>';

  enabled = true;
  show = false;
  save = false;
  events: [consts.ModalEvents.OK_EVENT, consts.ModalEvents.CANCEL_EVENT];
  params = {};
  revalidate = 0;
  comment = '';
  btnSave = 0;

  sample: types.Sample = null;

  sectionTitle = '';
  invalidate = 0;

  get status() {
    return consts.SampleStatus.byId(this.sample.status_id);
  }

  showModal(sample: types.Sample = null) {
    console.log('ModalSample showModal', sample);
    this.show = true;

    this.sample = sample;
    //  this.fillStages();
  }
  async saveModal() {
    /*
    this.save = true;
    this.params['save'] = true;
    this.params['new'] = this.isNew;
    if (this.isNew) {
      this.method.add_date_time = moment().format(consts.DB_DATE_TIME_FORMAT);
    }
    this.method['stages'] = this.stages;
    console.log('Saving method', this.method);
    */
    //let response = null;
    /*
    if (this.isNew) {
      response = await methodsModule.addMethod(this.method);
    } else {
      response = await methodsModule.updateMethod(this.method);
    }*/
    /*
    if (response.data.success === false && response.data.error_code === METHOD_NAME_ALREADY_EXISTED) {
      console.log('Method name already existed');
      helpers.error('Podana nazwa metody oraz norma są już wykorzystane, użyj innej pary');
      this.btnSave++;
      return;
    }
    this.method.id = response.data.method['id'];
   
    this.method = Object.assign(new Method(), this.method);
    this.method.load();
    this.params['object'] = this.method;
    console.log('ModalMethod end', this.params['object']);
    this.$emit(consts.ModalEvents.OK_EVENT, this.params);
    this.show = false;
    */
  }
  hideModal() {
    this.params['save'] = this.save;
    this.$emit(consts.ModalEvents.CANCEL_EVENT, this.params);
    this.show = false;
  }
}
