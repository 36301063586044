import Vue from "vue";
export const EventBus: Vue = new Vue();

export enum BusEvents {
  CHANGE_TREE_VISIBLE = "change-tree-visible",
  CHANGE_HEADER_VISIBLE = "change-header-visible",
  PRINT_PDF = "PRINT_PDF",
  AUTHORISE = "AUTHORISE",
  LOGIN_PROGRESS = "LOGIN_PROGRESS",
  CLEAR = "CLEAR",
}

export function updateBreadcrumb() {
  console.log("updateBreadcrumb emitting...");
  EventBus.$emit("breadcrumb-update");
  console.log("updateBreadcrumb emitted");
}

/*
export function initHeader(mainTitle: string, mainIcon: string, elements: object []) {
    EventBus.$emit("header-init", {
        mainTitle: mainTitle,
        mainIcon: mainIcon,
        elements: elements
    });
}

export function initFooter(leftElements:object [], rightElements: object [], middle: object []) {
    EventBus.$emit("footer-init", {
        rightElements: rightElements,
        leftElements: leftElements,
        middle: middle
    });
}
*/
