































































import * as API from '../store/api';
import VueRouter, { Route } from 'vue-router';
import { Component, Vue, Ref } from 'vue-property-decorator';
import AppModule from '../store/modules/app';
import * as helpers from '../helpers';
import store from '../store';
import VueHtml2pdf from 'vue-html2pdf';
import validator from 'validator';
import { EventBus, BusEvents } from '../helpers/eventbus';
import { User } from '@/types';
import * as consts from '@/consts';

@Component
export default class Login extends Vue {
  private email = '';
  private pass = '';
  loginError = '';
  max = 100;
  progressValue = 0;
  loginBtnKey = 0;
  newVersion = 'v1.0.19';

  get appVersion() {
    return AppModule.appVersion;
  }

  get isOnline() {
    return helpers.onlineHelper.isOnline;
  }

  // TO-DO rest of the validations
  private validate() {
    if (!this.email) {
      return 'Proszę wypełnić email';
    }
    if (!this.pass) {
      return 'Proszę wypełnić hasło';
    }
    return '';
  }
  clearErrors() {
    this.loginError = '';
  }
  // TO-DO change event resets errors
  async login() {
    this.loginError = this.validate();
    if (this.loginError) {
      this.loginBtnKey++;
      return;
    }
    EventBus.$emit(BusEvents.LOGIN_PROGRESS, 10);

    const response = await API.loginUser({
      email: this.email,
      password: this.pass,
    });

    const user = Object.assign(new User(), response.data.user);
    if (response && response.data && response.data.success && user !== undefined) {
      console.log('Login.vue login Response', response);
      store.commit('setCurrentUser', user);
      if (user.is_logged_in) {
        API.setJWT(user.token);
        if (user.user_data.module === consts.ModuleType.LAB) {
          this.$router.push('lab');
        } else if (user.user_data.module === consts.ModuleType.CLIENT) {
          this.$router.push('client');
        }
      } else {
        EventBus.$emit(BusEvents.LOGIN_PROGRESS, 0);
        this.loginError = 'Niepoprawny email lub hasło !!';
        this.loginBtnKey++;
      }
    } else {
      EventBus.$emit(BusEvents.LOGIN_PROGRESS, 0);
      this.loginError = 'Niepoprawny email lub hasło !!';
      this.loginBtnKey++;
    }
  }

  // TO-DO pokazać okno z pobraniem email, wyslac na niego nowy pass
  async reset() {
    console.log('Reset pass');
    if (!validator.isEmail(this.email)) {
      this.loginError = 'Aby zresetować podaj poprawny adres email';
      return;
    }

    await API.reset(this.email)
      .then(response => {
        console.log('Reset done', response);
        if (response.data.success === true) {
          helpers.info('Hasło zresetowane, sprawdź email');
        } else {
          helpers.error('Wystąpił błąd podczas resetowania hasła');
        }
      })
      .catch(err => {
        console.error(err);
        this.loginError = 'Niepoprawny email lub hasło !';
      });
  }

  makeProgress(value: number) {
    this.progressValue = value;
  }
  refreshing = false;
  registration = null;
  updateExists = false;
  currentVersion = '';

  showRefreshUI(e) {
    console.log('Login showRefreshUI');
    this.registration = e.detail;
    this.updateExists = true;
  }
  refreshApp() {
    this.updateExists = false;

    if (!this.registration || !this.registration.waiting) {
      console.log('Login refreshApp !');
      return;
    }
    console.log('Login refreshApp post SKIP_WAITING');
    this.registration.waiting.postMessage('SKIP_WAITING');
  }

  setLogin(to = 'piotr') {
    if (to === 'piotr') {
      this.email = 'piotr.plonka@prym-soft.pl';
      this.pass = '***';
    }
  }

  mounted() {
    this.currentVersion = process.env.VUE_APP_VERSION;
    document.addEventListener('swUpdated', this.showRefreshUI, { once: true });
    if (navigator && navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) {
          console.log('Login this.refreshing');
          return;
        }
        console.log('Login this.refreshing false, reload');
        this.refreshing = true;
        window.location.reload();
      });
    }
    this.setLogin();
    EventBus.$on(BusEvents.LOGIN_PROGRESS, this.makeProgress);
  }
}
