










































































































































import { Component, Vue, Ref } from 'vue-property-decorator';
import moment from 'moment';

import TopPanel from '../../../components/dummy/TopPanel.vue';
import Header from '../../../components/dummy/Header.vue';
import Footer from '../../../components/dummy/Footer.vue';
import ModalExamFilter from '../../../components/spec/ModalExamFilter.vue';

import { SortDir, LABORANT_ROUTE_PREFIX, DashboardObjectTypes, DATE_TIME_FORMAT } from '../../../consts';
import { AppView, Exam, DashboardItem, User, DashboardAction, Sample } from '../../../types';

@Component({
  components: {
    TopPanel,
    Header,
    Footer,
    ModalExamFilter,
  },
})
export default class ExamList extends Vue {
  @Ref() readonly modalExamFilter: ModalExamFilter;

  btnPrevVisible = false;
  btnNextVisible = true;

  pageSize = 20;
  pageNo = 1;
  allPagesCount = 0;
  filterParams: { field: ''; value: any }[] = [];
  sortParams = { field: 'changed_date_time', dir: SortDir.ASC };
  spinner = false;
  exams: Exam[] = [];

  fillExams() {
    console.log('fillExams');
    this.spinner = true;
    this.exams = [];
    let exams = []; //examsModule.allExams;

    if (this.filterParams.length > 0) {
      console.log('Filtering', this.filterParams);
      exams = this.filterExam(exams, this.filterParams);
    }
    console.log('Sorting', this.sortParams);
    exams = this.sortExam(exams, this.sortParams.field, this.sortParams.dir);
    let index = 1;
    console.log('Exams', exams);
    exams.forEach(e => {
      console.log('Exam', e);
      e.load();
      index++;
      e['selected'] = false;
    });
    this.allPagesCount = Math.ceil(exams.length / this.pageSize);
    console.log('Paginating pageSize=', this.pageSize, 'pageNo=', this.pageNo);
    this.exams = this.paginate(exams, this.pageSize, this.pageNo) as Exam[];
    this.spinner = false;
    this.update();
  }

  getClientName(client_id: number) {
    if (client_id !== undefined) {
      const client = null; //dictModule.clientById(client_id);
      return client !== undefined ? client.name : '';
    } else {
      return '';
    }
  }

  getCPlaceName(cplace_id: number) {
    if (cplace_id !== undefined) {
      const cplace = null; //dictModule.cPlaceById(cplace_id);
      return cplace !== undefined ? cplace.name : '';
    } else {
      return '';
    }
  }

  get examSel() {
    return (exam: Exam): boolean => {
      return this.exams.find(e => e.id === exam.id)['selected'];
    };
  }

  // ---------------- ROUTES ------------------
  editExam(event: any, item: any) {
    console.log('Exam edit');
  }
  get user(): User {
    return null; //userModule.user;
  }

  // ----------------- INTERNAL ACTIONS ----------------
  prevPage() {
    this.pageNo--;
    this.fillExams();
  }

  nextPage() {
    this.pageNo++;
    this.fillExams();
  }

  changePageSize() {
    this.update();
    this.fillExams();
  }

  filterPage() {
    console.log('FilterModal');
    this.modalExamFilter.showModal();
  }

  sortPage(sortField = 'id') {
    if (this.sortParams.field !== sortField) {
      this.sortParams.dir = SortDir.ASC; // pierwszy raz zawsze ASC
    } else {
      this.sortParams.dir = this.sortParams.dir === SortDir.ASC ? SortDir.DESC : SortDir.ASC;
    }
    this.sortParams.field = sortField;
    this.fillExams();
  }

  update() {
    console.log('update', this.pageNo, this.allPagesCount);
    this.btnNextVisible = this.pageNo !== this.allPagesCount;
    this.btnPrevVisible = this.pageNo !== 1;
  }

  sortExam(array: Exam[], field: string, dir: SortDir): Exam[] {
    console.log('Sorting ', field, dir);
    switch (field) {
      case 'id':
      case 'number':
      case 'name':
      case 'acreditation':
      case 'status_id':
        console.log('Sorting field number');
        return array.sort((a, b) => {
          if (dir === SortDir.ASC) {
            return a[field] < b[field] ? 1 : -1;
          } else {
            return a[field] > b[field] ? 1 : -1;
          }
        });
      case 'group.name':
        return array.sort((a, b) => {
          if (dir === SortDir.ASC) {
            return a['group']['name'] < b['group']['name'] ? 1 : -1;
          } else {
            return a['group']['name'] > b['group']['name'] ? 1 : -1;
          }
        });
      case 'changed_date_time':
        return array.sort((a, b) => {
          //console.log("DATES", moment(a[field], DATE_TIME_FORMAT), moment(b[field], DATE_TIME_FORMAT));
          if (dir === SortDir.ASC) {
            return moment(a[field], DATE_TIME_FORMAT).isAfter(moment(b[field], DATE_TIME_FORMAT)) ? -1 : 1;
          } else {
            return moment(a[field], DATE_TIME_FORMAT).isBefore(moment(b[field], DATE_TIME_FORMAT)) ? -1 : 1;
          }
        });
      default:
        return array;
    }
  }

  filterExam(exams: Exam[], filters: { field: string; value: any }[]) {
    //console.log("Filter Array", filters);
    let result = false;
    return exams.filter(exam => {
      result = true;
      console.log('########## EXAM ', exam.id, ' ###########');
      filters.forEach((filter, index) => {
        if (result) {
          const fieldSplitted = filter.field.split('.');
          console.log('-- FILTER ', index + 1, filter.field, filter.value, 'splitted', fieldSplitted);
          if (fieldSplitted.length === 1) {
            if (typeof filter.value === 'string') {
              console.log('String', exam[filter.field], filter.value);
              result = exam[filter.field].includes(filter.value);
            } else if (typeof filter.value === 'number' || typeof filter.value === 'boolean') {
              console.log('Number', exam[filter.field], filter.value);
              result = exam[filter.field] === filter.value;
            }
          } else if (fieldSplitted.length === 2) {
            if (typeof filter.value === 'string') {
              result = exam[fieldSplitted[0]][fieldSplitted[1]].includes(filter.value);
            } else if (typeof filter.value === 'number' || typeof filter.value === 'boolean') {
              console.log('Number nested curr:', exam[fieldSplitted[0]][fieldSplitted[1]], 'required', filter.value);
              result = exam[fieldSplitted[0]][fieldSplitted[1]] === filter.value;
            }
          }
        }
        console.log('Result', result);
      });
      return result;
    });
  }

  paginate(array: Exam[], pageSize: number, pageNumber: number): object[] {
    pageNumber--;
    const rowFrom: number = +(pageNumber * pageSize);
    const rowTo: number = +(pageNumber * pageSize) + +pageSize;
    console.log('Page [' + rowFrom, '-', rowTo + ']');
    let index = 1;
    array.forEach(e => {
      e['index'] = index++;
    });
    return array.slice(rowFrom, rowTo);
  }

  modalExamFilterOK(filters: any) {
    console.log('modalExamFilterOK FILTERS:', filters);
    this.filterParams = filters;
    this.fillExams();
  }
  modalExamFilterCancel() {
    console.log('modalExamFilterCancel');
  }

  created() {
    console.log('%c*********************** ExamsList creating ***********************', 'color:red');
    this.fillExams();
    /*
    breadcrumbModule.setBreadcrumb({
      routePrefix: '/lab',
      items: [
        {
          title: 'Lista badań',
          link: '',
        },
      ],
    });
    */
    console.log('%c*********************** ExamsList created ***********************', 'color:red');
  }
  get start() {
    console.log('%c---------- Start of template -----------', 'color:green;font-weight:600');
    return '';
  }
  get end() {
    console.log('%c---------- End of template -----------', 'color:green;font-weight:600');
    return '';
  }
}
