

































































































import { Component, Vue } from 'vue-property-decorator';
import moment from 'moment';
import * as consts from '../../consts';
import TopPanel from '../../components/dummy/TopPanel.vue';
import DashboardSimplePanel from '@/components/spec/DashboardSimplePanel.vue';
import DashboardListPanel from '@/components/spec/DashboardListPanel.vue';
import DateInput from '../../components/dummy/DateInput.vue';
import * as API from '../../store/api';
import {
  DashboardStatistics,
  DashboardReport,
  DashboardExternalOrder,
  DashboardMethod,
  DashboardLaborant,
} from '@/types';

@Component({
  components: {
    TopPanel,
    DashboardSimplePanel,
    DashboardListPanel,
    DateInput,
  },
})
export default class Dashboard extends Vue {
  async created() {
    console.log('%c*********************** Dashboard creating ***********************', 'color:red');

    console.log('%c*********************** Dashboard created ***********************', 'color:red');
  }

  get start() {
    console.log('%c---------- Start of template -----------', 'color:green;font-weight:600');
    return '';
  }
  get end() {
    console.log('%c---------- End of template -----------', 'color:green;font-weight:600');
    return '';
  }

  reportClicked(report: DashboardReport) {
    this.$router.push({ path: `/lab/reports`, query: { number: report.number } });
  }

  externalOrderClicked(sample: DashboardExternalOrder) {
    this.$router.push({ path: `/lab/samplesboard`, query: { number: sample.number } });
  }

  methodClicked(method: DashboardMethod) {
    this.$router.push({ path: `/lab/methodlist`, query: { number: method.number } });
  }
}
