














































































import { Component, Vue, Prop, Ref, Emit } from 'vue-property-decorator';
import * as consts from '../../consts';
import { DashboardItem, DashboardAction, User } from '../../types';
import moment from 'moment';

@Component
export default class ModalAction extends Vue {
  @Prop({ type: String, required: false, default: '' }) title;
  @Prop({ type: String, required: false, default: '' }) message;
  @Prop({ type: String, required: false, default: '' }) messageClass;
  @Prop({ type: String, required: false, default: '' }) titleClass;
  @Prop({ type: String, required: false, default: 'lg' }) size;

  show = false;
  save = false;
  events: [consts.ModalEvents.OK_EVENT, consts.ModalEvents.CANCEL_EVENT];
  params = {};
  item: DashboardItem = null;
  lastMove = {};
  revalidate = 0;
  workerSelected: User = this.workers[0] as User;
  peopleSelected: User[] = [];
  comment = '';
  btnSave = 0;

  @Emit()
  modalClose() {
    return 1;
  }

  get workerSel() {
    return this.workerSelected;
  }
  workerItemSelected(event) {
    this.workerSelected = event;
  }

  get getOperType() {
    return this.item.operation_type;
  }

  get getOldStatus() {
    return this.lastMove['oldStatus'] || '';
  }
  get getNewStatus() {
    return this.lastMove['newStatus'];
  }

  showModal(params: object) {
    console.log('ModalAction::showModal', params);
    this.comment = '';
    this.show = true;
    this.params = params;
    this.item = params['item'];
    this.lastMove = params['lastMove'];
    this.params['people'] = [];
  }
  async saveModal() {
    this.save = true;
    this.params['save'] = true;
    console.log('ModalAction::saveModal', this.params);

    const newItem = this.params['item'] as DashboardItem;
    console.log('newitem', newItem);
    this.params['comment'] = this.comment;

    const newAction = new DashboardAction();
    newAction.index = 0;
    newAction.from_status_id = this.params['lastMove']['oldStatusId'];
    newAction.to_status_id = this.params['lastMove']['newStatusId'];
    newAction.dashboard_item_id = newItem.id;
    newAction.comment = this.params['comment'];
    newAction.action_date_time = moment().format(consts.DB_DATE_TIME_FORMAT);
    newAction.action_person = null; //userModule.user;
    newAction.action_person_id = -1; //userModule.user.id;
    newAction.new_status_person = this.workerSelected;
    newAction.new_status_person_id = this.workerSelected.id;

    newItem.status_id = newAction.to_status_id;

    /*
    let response = null;
    /*
    if (newAction.from_status_id === -1) {
      response = await dashboardItemsModule.addItemWithAction({ item: newItem, action: newAction });
    } else {
      response = await dashboardItemsModule.updateItemWithAction({ item: newItem, action: newAction });
    }
    if (!response.data.success) {
      console.log('Error while saving action');
    } else {
      //this.params['item'] = dashboardItemsModule.dashboardItemById(response.data.item.id);
      //this.params['action'] = dataModule.actionById(response.data.action.id);
    }*/
    this.$emit(consts.ModalEvents.OK_EVENT, this.params);
    this.show = false;
  }
  hideModal() {
    this.params['save'] = this.save;
    this.$emit(consts.ModalEvents.CANCEL_EVENT, this.params);
    this.show = false;
  }

  addPerson() {
    if (!this.params['people'].find(p => p.id === this.workerSelected.id)) {
      //this.peopleSelected.push(usersModule.userById(this.workerSelected.id));
      this.params['people'] = this.peopleSelected;
    }
  }
  removePerson(person: User) {
    this.peopleSelected = this.peopleSelected.filter(p => p.id !== person.id);
    this.params['people'] = this.peopleSelected;
  }
  get workers() {
    this.revalidate;
    //console.log('usersModule:users', usersModule.allUsers);
    const optionsUsers = []; //usersModule.getUsersByRightSymbol(consts.UserRightSymbols.TOOL_DASHBOARD_PROCESS).map(u => {
    // retUser = { name: u.firstname + ' ' + u.lastname, id: u.id };
    //return retUser;
    //});
    return optionsUsers;
  }
  descrChange(event) {
    this.comment = event;
  }
}
