



































































import { Component, Vue, Prop, Ref, Emit } from 'vue-property-decorator';
import * as consts from '../../consts';
import { DashboardItem, Method, User, MethodGroup } from '../../types';
import moment from 'moment';

const NOT_CHOOSEN = 100;

@Component
export default class ModalExamFilter extends Vue {
  @Prop({ type: String, required: false, default: '' }) title;
  @Prop({ type: String, required: false, default: '' }) message;
  @Prop({ type: String, required: false, default: '' }) messageClass;
  @Prop({ type: String, required: false, default: '' }) titleClass;
  @Prop({ type: String, required: false, default: 'lg' }) size;

  enabled = false;
  show = false;
  save = false;
  events: [consts.ModalEvents.OK_EVENT, consts.ModalEvents.CANCEL_EVENT];
  params = {};
  revalidate = 0;
  btnSave = 0;

  showModal() {
    this.show = true;
  }
  addFilter(filters: { field: string; value: any }[], field: string, value: any) {
    filters.push({ field: field, value: value });
  }
  saveModal() {
    this.save = true;
    const filters = [];
    this.$emit(consts.ModalEvents.OK_EVENT, filters);
    this.show = false;
  }
  clear() {
    console.log('Clear');
  }
  hideModal() {
    this.params['save'] = this.save;
    this.$emit(consts.ModalEvents.CANCEL_EVENT);
    this.show = false;
  }
}
