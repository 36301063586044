




















import { Component, Vue, Prop } from 'vue-property-decorator';
import GenericForm from '@/components/spec/GenericForm.vue';
import { fetchStatistics } from '@/store/api';
import { DashboardStatisticName } from '@/types';

@Component({
  components: {
    GenericForm,
  },
})
export default class DashboardSimplePanel extends Vue {
  @Prop({ required: true }) title;
  @Prop({ required: true }) icon;
  @Prop({ required: true }) subtitle;
  @Prop({ required: true }) statisticName: DashboardStatisticName;
  loading = true;
  mainValue = 0;

  async created() {
    const response = await fetchStatistics(this.statisticName);
    if (response.data.success) {
      this.mainValue = response.data[this.statisticName];
      this.loading = false;
    }
  }
}
