











































import { Component, Vue, Ref, Prop, Watch } from 'vue-property-decorator';
import moment from 'moment';

import TopPanel from '@/components/dummy/TopPanel.vue';
import Header from '@/components/dummy/Header.vue';
import Footer from '@/components/dummy/Footer.vue';
import ModalExamFilter from '@/components/spec/ModalExamFilter.vue';
import GenericForm from '@/components/spec/GenericForm.vue';
import GenericField from '@/components/spec/GenericField.vue';

import { SortDir, LABORANT_ROUTE_PREFIX, DashboardObjectTypes, DATE_TIME_FORMAT } from '@/consts';
import { AppView, Exam, DashboardItem, User, DashboardAction, Sample, ExtendedParam } from '@/types';
import * as API from '@/store/api';

@Component({
  components: {
    TopPanel,
    GenericForm,
    Header,
    Footer,
    ModalExamFilter,
    GenericField,
  },
})
export default class MinimalGenericList extends Vue {
  @Prop() readonly title: string;
  @Prop({ default: false }) readonly hideHeader: boolean;
  @Prop() readonly objects: object[];
  @Prop() readonly paramsDisplay: ExtendedParam[];
  @Prop() readonly buttonTitle: string;
  @Prop() readonly processLoadedElement: any;
  @Prop({ default: false, required: false }) readonly bottomRowBorder: boolean;
  @Prop() readonly model: string;
  @Prop({ default: true, required: false }) readonly showNumeration: boolean;
  @Prop({ default: false, required: false }) readonly checkable: boolean;
  @Ref() fields: GenericField[];
  @Prop({
    default() {
      return {};
    },
  })
  readonly filterParams: object;
  btnPrevVisible = false;
  btnNextVisible = true;
  objectSelected: object = {};
  objectsVisible = [];

  reload() {
    this.$forceUpdate();
    this.fields.forEach(el => {
      el.$forceUpdate();
    });
  }

  get getColumnStyle() {
    return (param: ExtendedParam) => {
      if (param.columnCss) return param.columnCss;
      if (param.headerCss) return param.headerCss;
      return 'col-1 p-0 text-center';
    };
  }

  emitEntity(obj: object) {
    this.$emit(this.ENTITY_PROCESS, obj);
  }

  emitFilter() {
    this.$emit('FILTER');
  }

  pageSize = 20;
  ENTITY_PROCESS = 'ENTITY_PROCESS';
  pageNo = 1;
  allPagesCount = 0;
  //filterParams: { field: ''; value: any }[] = [];
  sortParams = { field: 'id', dir: SortDir.ASC };
  spinner = false;

  sortPage(sortField = 'id') {
    if (this.sortParams.field !== sortField) {
      this.sortParams.dir = SortDir.ASC; // pierwszy raz zawsze ASC
    } else {
      this.sortParams.dir = this.sortParams.dir === SortDir.ASC ? SortDir.DESC : SortDir.ASC;
    }
    this.sortParams.field = sortField;
    //this.fillObjects();
  }

  sortObejcts(array: object[], field: string, dir: SortDir): object[] {
    console.log('Sorting ', field, dir);
    switch (field) {
      default:
        console.log('Sorting field number');
        return array.sort((a, b) => {
          if (dir === SortDir.ASC) {
            return a[field] < b[field] ? 1 : -1;
          }
        });
    }
  }
}
