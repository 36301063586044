











































import { Component, Vue, Prop } from 'vue-property-decorator';
import { DashboardStatisticName, DashboardStatistics } from '@/types';
import { fetchStatistics } from '@/store/api';
import moment from 'moment';
import { DB_DATE_TIME_FORMAT, DATE_FORMAT } from '@/consts';

@Component({
  components: {},
})
export default class DashboardSimplePanel extends Vue {
  @Prop({ required: true }) title: string;
  @Prop({ required: false }) icon: string;
  @Prop({ required: true }) statisticName: DashboardStatisticName;
  @Prop({ required: true }) columns: any[];
  @Prop({ required: true }) columnHeaders: string[];
  @Prop({ required: true }) moreDataLink: string;
  @Prop({ required: false, default: () => [] }) leftAlignedColumns: string[];
  @Prop({ required: false, default: '' }) columnWithLink: string;
  list: DashboardStatistics = [];
  loading = true;

  get gridStyle() {
    return {
      gridTemplateColumns: `repeat(${this.columnHeaders.length + 1}, auto)`,
    };
  }

  get cellStyle() {
    return (span: number) => ({
      gridColumn: `span ${span}`,
    });
  }

  get formattedCols() {
    return this.columns.map(column => {
      if (column.value) return column;
      return { value: column };
    });
  }

  async created() {
    const response = await fetchStatistics(this.statisticName);
    if (response.data.success) {
      this.list = response.data[this.statisticName];
      if (this.statisticName === 'top_laborants') {
        this.list.forEach(laborant => {
          laborant.fullName = `${laborant.test_laborant__first_name} ${laborant.test_laborant__last_name}`;
        });
      } else if (this.statisticName === 'last_reports') {
        this.list.forEach(report => (report.date = moment(report.date, DB_DATE_TIME_FORMAT).format(DATE_FORMAT)));
      }
      this.loading = false;
    }
  }
}
