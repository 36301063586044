











import { Vue, Component } from 'vue-property-decorator';
import LaborantMenu from '@/components/spec/LaborantMenu.vue';
import TopPanel from '@/components/dummy/TopPanel.vue';
import ClientMenu from '@/components/spec/ClientMenu.vue';
import Header from '@/components/dummy/Header.vue';
import Footer from '@/components/dummy/Footer.vue';

@Component({
  components: { Footer, Header, TopPanel, LaborantMenu, ClientMenu },
})
export default class Client extends Vue {
  onResize() {
    console.log('InnerWidth:', window.innerWidth);
    //app.setMobile(window.innerWidth < 600);
  }

  mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResize);
  }

  get getInner() {
    return window.innerWidth;
  }

  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize);
    }
  }
}
