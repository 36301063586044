








import { Component, Vue, Prop } from 'vue-property-decorator';
@Component({})
export default class Checkbox extends Vue {
  @Prop({ default: '', type: String }) readonly mainCss;
  @Prop({ default: '', type: String }) readonly headerCss;
  @Prop({ default: '', type: String }) readonly css;

  @Prop({ default: '', type: String }) readonly headerValue;

  @Prop({ default: false, type: Boolean }) readonly hideHeader;

  @Prop({ required: true }) object;
  @Prop({ required: true }) modelField: string;

  emitChange() {
    this.$emit('change');
  }
}
