import { User } from '@/types';
import { Component, Vue } from 'vue-property-decorator';
import moment from 'moment';
import { DATE_FORMAT } from '@/consts';

@Component
export default class DashboardMixin extends Vue {
  get user() {
    return null; //userModule.user;
  }
  get start() {
    console.log('%c---------- Start of template -----------', 'color:green;font-weight:600');
    return '';
  }
  get end() {
    console.log('%c---------- End of template -----------', 'color:green;font-weight:600');
    return '';
  }
}
