


























































































































































































































































































































































































































import { Component, Vue, Prop, Ref, Emit } from 'vue-property-decorator';
import * as consts from '../../consts';
import { DashboardItem, Method, User, MethodGroup, MethodStage, MethodStageParam, Tool } from '../../types';
import moment from 'moment';
import * as helpers from '../../helpers';

enum SectionView {
  PARAMS,
  TOOLS,
}

const METHOD_NAME_ALREADY_EXISTED = 2;

@Component
export default class ModalMethod extends Vue {
  @Prop({ type: String, required: false, default: '' }) title;
  @Prop({ type: String, required: false, default: '' }) message;
  @Prop({ type: String, required: false, default: '' }) messageClass;
  @Prop({ type: String, required: false, default: '' }) titleClass;
  @Prop({ type: String, required: false, default: 'lg' }) size;

  leftArrowSign = '<<';
  rightArrowSign = '>>';

  enabled = false;
  show = false;
  isNew = false;
  save = false;
  events: [consts.ModalEvents.OK_EVENT, consts.ModalEvents.CANCEL_EVENT];
  params = {};
  revalidate = 0;
  comment = '';
  btnSave = 0;
  orginalMethod: Method = null;
  item: DashboardItem = null;

  SectionViewPARAMS = SectionView.PARAMS;
  SectionViewTOOLS = SectionView.TOOLS;

  groupItemSelected: MethodGroup = null;
  method: Method = null;

  stages: MethodStage[] = [];
  sectionTitle = '';
  invalidate = 0;
  toolInvalidate = 0;
  protInvalidate = 0;
  rdInvalidate = 0;

  addGroupToProtMethodEnabled = false;
  addGroupToRDMethodEnabled = false;
  invalidateRight = 0;

  get methodUsers() {
    return this.method.method_users;
  }

  get getStages() {
    this.invalidate;
    return this.stages;
  }

  get getStageTools() {
    return (stage: MethodStage): Tool[] => {
      this.toolInvalidate;
      return stage.tools;
    };
  }
  choosenProtDestinyGroups = [];
  availableProtDestinyGroups = [];

  newStageTab() {
    const ms = new MethodStage();
    ms.method_id = this.method.id;
    ms['index'] = this.stages.length + 1;
    console.log('STAGES', ms, this.stages);
    ms['section'] = SectionView.PARAMS;
    ms['params'] = [];
    ms['tools'] = [];
    this.stages.push(ms);
    this.invalidate++;
  }

  removeStage(index: number) {
    this.stages.splice(index, 1);
  }

  paramsClick(stage: MethodStage) {
    stage['section'] = SectionView.PARAMS;
    this.invalidate++;
  }
  toolsClick(stage: MethodStage) {
    console.log('section Tools');
    stage['section'] = SectionView.TOOLS;
    this.invalidate++;
  }
  changeParamClick(e: any, stage: MethodStage, param: MethodStageParam) {
    param['edit'] = !param['edit'];
  }
  removeParamClick(e: any, stage: MethodStage, param: MethodStageParam) {
    stage['params'] = stage['params'].filter(p => p.id !== param.id);
  }

  changeToolClick(e: any, stage: MethodStage, tool: Tool) {
    tool['edit'] = !tool['edit'];
    this.toolInvalidate++;
  }
  removeToolClick(e: any, stage: MethodStage, tool: Tool) {
    stage['tools'] = stage['tools'].filter(t => t.id !== tool.id);
    this.toolInvalidate++;
  }
  addParam(e: any, stage: MethodStage) {
    const param = Object.assign(new MethodStageParam(), {
      id: 0,
      name: '-- nazwa --',
      unit: '-jedn-',
      formula: '-- formuła --',
      method_stage_id: stage.id,
      symbol: '-- symbol --',
      on_rd: true,
      edit: true,
    });
    helpers.setTempId(stage['params'], param);
    stage['params'].push(param);
  }

  get getToolsNotUsed() {
    return (stage: MethodStage) => {
      return []; //toolsModule.allTools.filter(t => !stage.tools.includes(t)) || [];
    };
  }

  addTool(e: any, stage: MethodStage) {
    const toolsNotUsed = this.getToolsNotUsed(stage);
    console.log('toolsNotUsed', toolsNotUsed, toolsNotUsed[0].name);
    const tool = toolsNotUsed[0];
    tool['edit'] = true;
    stage['tools'].push(tool);
    this.toolInvalidate++;
    console.log('Tool added', tool, stage['tools']);
  }

  get allTools() {
    return (stage: MethodStage, tool: Tool) => {
      this.toolInvalidate;
      return this.getToolsNotUsed(stage).filter(t => t.id !== tool.id);
    };
  }

  get selTool() {
    return (stage: MethodStage, tool: Tool) => {
      this.toolInvalidate;
      console.log('seltool', tool);
      if (tool !== undefined) {
        return stage['tools'].find(t => t.id === tool.id);
      } else {
        return {};
      }
    };
  }
  toolSelected(e: any, stage: MethodStage, tool: Tool) {
    console.log('selected tool', e, tool);
    stage['tools'].splice(
      stage['tools'].findIndex(t => t.id === tool.id),
      1,
      e as Tool
    );
  }

  fillStages() {
    const stages = []; //methodsModule.allMethodStages.filter(s => s.method_id === this.method.id);
    stages.forEach(s => {
      s['section'] = SectionView.PARAMS;
      s['params'] = this.getStageParams(s);
      s['tools'] = this.fillStageTools(s);
    });
    console.log('Stages:', stages);
    this.stages = stages;
  }

  get getStageParams() {
    return (stage: MethodStage) => {
      const params = []; //methodsModule.allMethodStageParams.filter(p => p.method_stage_id === stage.id);
      params.forEach(p => {
        p['edit'] = false;
      });
      return params;
    };
  }

  get fillStageTools() {
    return (stage: MethodStage): Tool[] => {
      const tools = [] //methodsModule.allMethodStageTools
        .filter(s => s.method_stage_id === stage.id)
        .map(s => {
          return null; //toolsModule.toolById(s.tool_id);
        });
      tools.forEach(t => {
        t['edit'] = false;
      });
      return tools;
    };
  }

  get status() {
    return consts.MethodStatus.byId(this.method.status_id);
  }

  get groupSel() {
    return this.groupItemSelected;
  }
  get methodGroups() {
    return []; //dictModule.allMethodGroups;
  }
  groupSelected(event) {
    this.groupItemSelected = event;
    this.method.group_id = this.groupItemSelected.id;
  }

  showModal(params: DashboardItem = null) {
    console.log('MethodModal params showModal', params);
    this.show = true;

    this.isNew = params === null;
    if (this.isNew) {
      console.log('Add method');
      this.item = new DashboardItem();
      this.item.object_type_id = consts.DashboardObjectTypes.METHOD;
      this.params = this.item;
      this.enabled = true;
      this.params['enabled'] = true;
      this.method = new Method();
      this.method.group_id = this.methodGroups[0].id;
      this.groupItemSelected = this.methodGroups[0];
      this.stages = [];
    } else {
      console.log('Edit method');
      this.params = params;
      this.item = params;
      this.method = JSON.parse(JSON.stringify(params.object));
      this.groupItemSelected = this.method['group'];
      this.orginalMethod = params.object as Method;
      this.fillStages();
    }
    console.log('showModal', this.item, this.isNew);
  }
  async saveModal() {
    this.save = true;
    this.params['save'] = true;
    this.params['new'] = this.isNew;
    if (this.isNew) {
      this.method.add_date_time = moment().format(consts.DB_DATE_TIME_FORMAT);
    }
    this.method['stages'] = this.stages;
    console.log('Saving method', this.method);
    //let response = null;
    /*
    if (this.isNew) {
      response = await methodsModule.addMethod(this.method);
    } else {
      response = await methodsModule.updateMethod(this.method);
    }*/
    /*
    if (response.data.success === false && response.data.error_code === METHOD_NAME_ALREADY_EXISTED) {
      console.log('Method name already existed');
      helpers.error('Podana nazwa metody oraz norma są już wykorzystane, użyj innej pary');
      this.btnSave++;
      return;
    }
    this.method.id = response.data.method['id'];
    */
    this.method = Object.assign(new Method(), this.method);
    this.method.load();
    this.params['object'] = this.method;
    console.log('ModalMethod end', this.params['object']);
    this.$emit(consts.ModalEvents.OK_EVENT, this.params);
    this.show = false;
  }
  hideModal() {
    this.params['save'] = this.save;
    this.$emit(consts.ModalEvents.CANCEL_EVENT, this.params);
    this.show = false;
  }
}
