
















import { Component, Vue, Prop } from 'vue-property-decorator';
@Component({})
export default class LabeledInput extends Vue {
  @Prop({ default: '', type: String }) readonly mainCss;
  @Prop({ default: '', type: String }) readonly headerCss;
  @Prop({ default: '', type: String }) readonly fieldCss;
  @Prop({ default: '', type: String }) readonly css;

  @Prop({ default: '', type: String }) readonly headerValue;
  @Prop({ default: '', type: String }) readonly maxLength;

  @Prop({ default: false, type: Boolean }) readonly hideHeader;

  @Prop({ required: true }) object;
  @Prop({ required: true }) modelField: string;

  emitInput() {
    this.$emit('INPUT', this.modelField, this.object);
  }

  emitChange() {
    this.$emit('CHANGE', this.modelField, this.object);
  }
}
