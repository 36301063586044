




























import * as helpers from '../helpers';
import * as consts from '../consts';
import { Client, DashboardItem, User, UserGroup } from '../types';
import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
import ModalClient from '../components/spec/ModalClient.vue';
import ModalUser from '../components/spec/ModalUser.vue';
import ModalChooseTime from '../components/dummy/ModalChooseTime.vue';
import TimeInput from '../components/dummy/TimeInput.vue';
import moment from 'moment';

@Component({
  components: {
    ModalClient,
    ModalUser,
    TimeInput,
  },
})
export default class Test extends Vue {
  @Ref() readonly modalClient: ModalClient;
  @Ref() readonly modalPerson: ModalUser;

  producerReceiverType: consts.ClientType = 1;
  prodRecvNameValue = '';
  prodRecvCodeValue = 0;
  prodRecvShortNameValue = '';
  enabled = true;
  load_time = '';
  time = '';

  timeChanged(time: string) {
    alert(time);
  }
  //:title="producerReceiverType === ClientType.PRODUCER ? 'Dodaj Producenta' : 'Dodaj Odbiorcę'"
  addProducerReceiver() {
    this.modalClient.showModal();
  }
  modalClientOK(client) {
    console.log('Modal Client OK', client);
    const newClient = new Client();
    newClient.short_name = this.prodRecvNameValue;
    newClient.name = this.prodRecvNameValue;
    //this.protocol.producer_receiver_id = newClient.id;
  }

  async addUser() {
    this.modalPerson.showModal();
  }
  async changeUser() {
    //const newUser = usersModule.users.find(u => u.id === 18);

    const item = new DashboardItem();
    item.object_type_id = consts.DashboardObjectTypes.PERSONEL;
    //item.object = newUser;
    item.enabled = true;

    this.modalPerson.showModal(item);
  }
  async previewUser() {
    const item = new DashboardItem();
    item.object_type_id = consts.DashboardObjectTypes.PERSONEL;
    //item.object = newUser;
    item.enabled = false;

    this.modalPerson.showModal(item);
  }
  modalPersonOK(params) {
    //usersModule.addUser(params.object);
  }
  async kkp() {
    const currDate = moment('17-01-2021', 'DD_MM_YYYY');
    //const exam = examsModule.examById(22);
    this.$router.push({
      path: 'lab/ce/cem/examcard/',
      name: 'examcard',
      params: {
        date: currDate.date() + '_' + (currDate.month() + 1).toString() + '_' + currDate.year(),
        //exam_id: exam.id.toString(),
      },
    });
  }

  modalChooseTimeOK(time: string) {
    this.load_time = time;
  }
}
