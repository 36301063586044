






import { Component, Vue } from 'vue-property-decorator';
import './helpers';
import Login from '@/views/Login.vue';

@Component({
  components: { Login },
})
export default class App extends Vue {
  get isMobile() {
    const ret = navigator.maxTouchPoints > 0;
    console.log('Mobile?', ret);
    return ret;
  }
  get agent() {
    console.log(navigator);
    return navigator.maxTouchPoints;
  }
}
