





































import { Component, Prop, Vue } from 'vue-property-decorator';
import * as API from '../../store/api';
import * as consts from '../../consts';
import * as helpers from '../../helpers';

@Component
export default class MainMenu extends Vue {
  @Prop({ required: false, default: '' }) routePrefix: string;
  @Prop({ type: Array, required: true })
  menuItems!: object[];

  @Prop({ type: String, required: true })
  menuName!: string;

  get isSubitemVisible() {
    return subitem => {
      if ('superuser' in subitem) {
        return this.$store.state.currentUser.is_superuser;
      }
      return subitem.module === consts.ModuleTypeString.LAB;
    };
  }

  private logoutUser(): any {
    API.clearJWT();
    this.$router.push({ name: 'Login' });
  }
  mounted() {
    if (!this.$store.state.currentUser.email) {
      helpers.warn('Użytkownik wylogowany. Zaloguj się ponownie');
      this.logoutUser();
    }
  }
}
