import Vue from 'vue';
import Vuex from 'vuex';
import { User } from '@/types';
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    resultsDocSampleIds: [],
    currentUser: new User(),
    templateSelected: {},
  },
  getters: {
    getResultsDocSampleIds: state => {
      return state.resultsDocSampleIds;
    },
    currentUser: state => {
      return state.currentUser as User;
    },
  },
  mutations: {
    setResultsDocSampleIds(state, arg_object) {
      state.resultsDocSampleIds = arg_object.ids;
      state.templateSelected = arg_object.template;
    },
    setCurrentUser(state, user: User) {
      console.log('Setting current user...', user);
      state.currentUser = user;
    },
    loadLocalStorage(state) {
      /*
      console.log("loadLocalStorage", state);
      const store = localStorage.getItem("store");
      //console.log(store);
      if (store != null) {
        this.replaceState(Object.assign(state, JSON.parse(store)));
      }
      */
    },
  },
  actions: {},
});

store.subscribe((mutation, state) => {
  /*
  const storeBeforeString = localStorage.getItem("store");
  if (storeBeforeString !== null) {
    const storeBeforeObj = {};
    Object.assign(storeBeforeObj, JSON.parse(storeBeforeString));
    console.log("LocalStorage STORE Before mutation:", storeBeforeObj);
  }
*/
  //console.log("%c" + "MUTATION subscribe=", "color:red", mutation, "state=", state);
  /*
  //localStorage.setItem('store', JSON.stringify(state));

  const storeAfterString = localStorage.getItem("store");
  if (storeAfterString !== null) {
    const storeAfterObj = {};
    Object.assign(storeAfterObj, JSON.parse(storeAfterString));
    console.log("LocalStorage STORE After mutation:", storeAfterObj);
  }*/
});

export default store;
