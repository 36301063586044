











































import { Component, Ref, Vue, Prop } from 'vue-property-decorator';
import * as consts from '../../consts';

@Component
export default class ModalRailDelivery extends Vue {
  @Prop({ type: String, required: false, default: '' }) title;
  @Prop({ type: String, required: false, default: '' }) message;
  @Prop({ type: String, required: false, default: '' }) messageClass;
  @Prop({ type: String, required: false, default: '' }) titleClass;
  @Prop({ default: 'Anuluj' }) readonly buttonCancelText: string;
  @Prop({ default: 'Zapisz' }) readonly buttonOKText: string;
  @Prop({ type: String, required: false, default: 'lg' }) size;
  @Prop({ type: Boolean, required: false, default: false }) backClose;
  @Prop({ type: Boolean, required: false, default: false }) okOnly;
  @Prop({ type: Boolean, required: false, default: true }) okVisible;
  @Prop({ type: String, required: false, default: 'success' }) okVariant;
  @Prop({ type: Boolean, required: false, default: false }) disabledOkButton;
  @Prop({ type: Boolean, required: false, default: true }) hideOnSave;

  enabled = false;
  show = false;
  save = false;
  events: [consts.ModalEvents.OK_EVENT, consts.ModalEvents.CANCEL_EVENT];
  params = {};
  revalidate = 0;
  btnSave = 0;

  showModal(params = {}) {
    this.show = true;
    this.params = params;
  }
  clear() {
    this.$emit('CLEAR');
  }
  saveModal() {
    this.save = true;
    this.$emit('SAVE', this.params);
    this.btnSave++;
    if (this.hideOnSave) {
      this.show = false;
    }
  }
  hideModal() {
    this.params['save'] = this.save;
    this.$emit(consts.ModalEvents.CANCEL_EVENT, this.params);
    this.show = false;
  }
}
