




















import { Component, Vue, Ref, Watch } from 'vue-property-decorator';
import ModalMessages from '../../components/spec/ModalMessages.vue';
import ModalSettings from '../../components/spec/ModalSettings.vue';
import * as helpers from '../../helpers';
import * as types from '../../types';
import userModule from '../../store';

@Component({
  components: {
    ModalMessages,
    ModalSettings,
  },
})
export default class ProfileMenu extends Vue {
  @Ref() readonly modalMessages: ModalMessages;
  @Ref() readonly modalSettings: ModalSettings;

  get user(): types.User {
    console.log('GetUSER', helpers.currentUser);
    return this.$store.state.currentUser;
  }

  unread: any = '';
  getUnreadMessages() {
    if (!this.modalMessages || !this.modalMessages.initialFetchDone) {
      return;
    }
    const res = this.modalMessages.getAllUnreadMessagesCount;
    this.unread = res;
  }

  mounted() {
    //init fetch loop
    setInterval(this.getUnreadMessages, 1000);
  }

  get fullname() {
    return this.user.fullName;
  }

  showMessages() {
    console.log('modalMessages', this.modalMessages);
    this.unread = this.modalMessages.getAllUnreadMessagesCount;
    this.modalMessages.showModal();
  }

  showSettings() {
    this.modalSettings.showModal();
  }

  private logoutUser(): any {
    //userModule.logout();
    this.$router.push({ name: 'Login' });
  }
}
